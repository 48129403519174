import React, { useEffect, useState } from "react";
import { Button } from "../components/Button";
import TextField from "../components/TextField";
import Select from "../components/Select";
import { allowedProfiles, bannerPallets } from "../utils/constants";
import { checkUserLoggedIn, isValidURL } from "../utils/service";
import { toast } from "react-toastify";
import axios, { axiosErrorToast } from "../utils/axios";
import { UIStore } from "../Store";
import ToggleSwitch from "../components/ToggleSwitch";
import LinkCard from "../components/LinkCard";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import UserPage from "./UserPage";
import UserDemoPage from "./UserDemo";
import CollorPallete from "../components/CollorPallete";

export interface LinkDetails {
  siteUrl: string[];
  siteName: string;
  isPromotionalContent: boolean;
  description: string;
}

function LinkAccounts({ uiStore }: any) {
  const [showTextField, setShowTextField] = useState(false);
  const [showOn, setShowOn] = useState(false);
  const { width, height } = useWindowSize();
  const [firstTime, setFirstTime] = useState(false);
  const [linkDetails, setLinkDetails] = useState<LinkDetails>({
    siteUrl: [],
    siteName: "",
    isPromotionalContent: showOn,
    description: "",
  });
  console.log(uiStore.userDetails);
  const [userDetails, setUserDetails] = useState(
    uiStore.userDetails ? uiStore.userDetails[0] : []
  );

  const [links, setLinks] = useState("");
  const [selectedColor, setSelectedColor] = useState(["default"]);

  function handleShowTextField() {
    console.log("called");
    setShowTextField(true);
  }

  useEffect(() => {
    if (!!uiStore.userDetails) {
      setUserDetails(uiStore.userDetails ? uiStore.userDetails[0] : []);
      setLinks(uiStore?.userDetails[0]?.socialMediaLinks);
      setSelectedColor([uiStore?.userDetails[0]?.bannerUrl ?? ["default"]]);
    }
  }, [uiStore.userDetails]);

  useEffect(() => {
    setLinkDetails({
      ...linkDetails,
      isPromotionalContent: showOn,
    });
  }, [showOn]);

  function handleSaveChanges() {
    const linkId = userDetails._id;

    const postData: any = {
      username: userDetails.username,
      userId: userDetails.userId,
      socialMediaLinks: links,
      bannerUrl: selectedColor ?? "",
    };

    axios
      .put(`/unilinks/${linkId}/update-link`, postData)
      .then((res) => {
        if (res.data.status === 200) {
          toast.success(res.data.message);
          setShowTextField(false);
          checkUserLoggedIn();
        }
      })
      .catch((err) => {
        axiosErrorToast(err);
      });
  }

  function handleAddLink() {
    const isValid = isValidURL(linkDetails.siteUrl[0]);

    console.log(uiStore.userDetails);

    if (!isValid) {
      toast.error("Invalid URL");
      return;
    }

    if (
      userDetails?.socialMediaLinks &&
      userDetails?.socialMediaLinks.length > 0
    ) {
      const linkId = userDetails._id;

      const postData: any = {
        username: userDetails.username,
        userId: userDetails.userId,
        socialMediaLinks: [...userDetails.socialMediaLinks, linkDetails],
      };

      axios
        .put(`/unilinks/${linkId}/update-link`, postData)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success(res.data.message);
            setShowTextField(false);
            checkUserLoggedIn();
          }
        })
        .catch((err) => {
          axiosErrorToast(err);
        });
    } else {
      const postData: any = {
        username: userDetails?.username,
        userId: userDetails?.userId,
        userType: "common",
        linksClick: 0,
        socialMediaLinks: [linkDetails],
        orderOfLinks: [],
        bannerUrl: "",
        profileUrl: "",
        coverUrl: "",
      };

      axios
        .post("/unilinks", postData)
        .then((res) => {
          if (res.data.status === 200) {
            console.log(!userDetails.socialMediaLinks, "asda");
            if (!userDetails.socialMediaLinks) {
              toast.success("Successfully linked your first account🎉.");
              setFirstTime(true);
            }
            toast.success(res.data.message);
            setShowTextField(false);
            checkUserLoggedIn();
          }
        })
        .catch((err) => {
          axiosErrorToast(err);
        });
    }
  }

  useEffect(() => {
    if (firstTime) {
      const timer = setTimeout(() => {
        setFirstTime(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [firstTime]);

  console.log(links);

  useEffect(() => {
    console.log("called3");
  }, [uiStore.userDetails]);

  if (uiStore.userDetails.length === 0) return <></>;
  return (
    <div className="">
      <div className="md:w-[100%] w-[50%] mt-[10px] flex justify-center mx-auto">
        {firstTime && <Confetti width={width} height={height} />}
        {!showTextField && (
          <div className="flex flex-col gap-[12px] items-center">
            <div className="flex gap-[20px]">
              <Button
                handleSubmit={handleShowTextField}
                text="+ Add Link"
                sx="md:w-[400px] w-[300px]"
              />

              <Button
                handleSubmit={handleSaveChanges}
                text="Save changes"
                sx="md:w-[400px] w-[300px]"
              />
            </div>

            <div className="w-[100%] flex">
              <div className="w-[60%] ">
                <LinkCard
                  links={links ?? []}
                  setLinks={setLinks}
                  uiStore={uiStore}
                />
              </div>
              <div className="w-[40%] flex flex-col items-center gap-3">
                <CollorPallete
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                />
                <UserDemoPage links={links} selectedColor={selectedColor} />
              </div>
            </div>
          </div>
        )}
      </div>

      {showTextField && (
        <div className="md:w-[400px] w-[300px] flex flex-col gap-[10px]">
          <TextField
            title="Enter site url"
            sx="p-[5px]"
            placeholder="https://www.example.com"
            value={linkDetails.siteUrl[0]}
            onChange={(e) =>
              setLinkDetails({
                ...linkDetails,
                siteUrl: [e],
              })
            }
          />

          <Select
            title="Select site name"
            onChange={(e) =>
              setLinkDetails({
                ...linkDetails,
                siteName: e.toString(),
              })
            }
            value={linkDetails.siteName}
            options={allowedProfiles}
          />

          <ToggleSwitch
            isOn={showOn}
            setIsOn={setShowOn}
            title="Promotional Content"
          />

          <TextField
            rows={5}
            title="Description"
            sx="p-[5px]"
            value={linkDetails.description}
            onChange={(e) =>
              setLinkDetails({
                ...linkDetails,
                description: e,
              })
            }
          />
          <Button text="Add" handleSubmit={handleAddLink} />
        </div>
      )}
    </div>
  );
}

export default LinkAccounts;
