import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AppLayout from "./layout/AppLayout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import LinkAccounts from "./pages/LinkAccounts";
import UserPage from "./pages/UserPage";
import { UIStore } from "./Store";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  const uiStore = UIStore.useState();

  const appRouter = createBrowserRouter([
    {
      path: "/",
      element: <AppLayout />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "/my-links/:username",
          element: <LinkAccounts uiStore={uiStore} />,
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: ":username",
      element: <UserPage />,
    },
    {
      path: "register",
      element: <Register />,
    },
    {
      path: "privacy-policy",
      element: <PrivacyPolicy />,
    },
  ]);

  return (
    <div className="font-poppins">
      <RouterProvider router={appRouter} />
    </div>
  );
}

export default App;
