import { useParams } from "react-router-dom";
import { socialMediaData } from "../utils/constants";
import bgImg from "../assets/6530.jpg";
import SampleLogo from "../components/SampleLogo";

function UserDemoPage({ links, selectedColor }: any) {
  console.log(links);
  let { username }: any = useParams();

  if (!links) return <></>;

  return (
    <div
      style={{
        background: `${
          selectedColor === "default" ? "url(" + bgImg + ")" : selectedColor
        }`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className="h-[70vh] rounded-lg w-[300px]"
    >
      <div className="h-full w-full flex flex-col  items-center mt-[40px] backdrop-blur-sm">
        <div className="mt-[40px]">
          <SampleLogo letter={username[0]} />
        </div>
        <p className="mt-[20px] text-[20px] font-bold uppercase text-black bg-blend-difference">
          {username ?? ""}
        </p>
        <div className="flex gap-[20px] px-[40px] py-[20px] flex-wrap items-center justify-center">
          {links.map((link: any) => (
            <div className="mt-[10px]">
              <a href={link.siteUrl[0]} target="_blank" rel="noreferrer">
                <img
                  src={
                    socialMediaData.find((item) => item.key === link.siteName)
                      ?.logoUrl
                  }
                  className="border-[0px]"
                  width={"30px"}
                  height={"30px"}
                  alt=""
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserDemoPage;
