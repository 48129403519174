import { Outlet } from "react-router-dom";
import { checkUserLoggedIn } from "../utils/service";
import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";
import { useEffect } from "react";
import { UIStore } from "../Store";

function AppLayout() {
  const currentPath = window.location.pathname;
  const uiStore = UIStore.useState();
  useEffect(() => {
    checkUserLoggedIn();
  }, []);
  return (
    <div className="min-h-[100vh] text-white font-poppins bg-[#000000] overflow-y-scroll">
      <Navbar />
      {uiStore.userLoggedIn && currentPath !== "/" ? (
        <div className="flex md:flex-row flex-col items-start">
          <SideBar />
          <Outlet />
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default AppLayout;
