import React, { useEffect } from "react";
import Banner from "../components/Banner";

function Home() {
  return (
    <>
      <Banner />

      {/* <div>{uiStore.message}</div> */}
    </>
  );
}

export default Home;
